import axios from 'axios';

import Config from 'config';



const getInstance = () => {
  return axios.create({
    withCredentials: true,
    baseURL: Config.server.backend,
    crossDomain: true
  })
}


export const onServer = (
  target, task, 
  inParams = {}, 
  callback = (response) =>   console.log(response), 
  failCallback = (fail) =>   console.log(fail),
  errorCallback = (error) => console.error(error) 
) => {
  var params = new URLSearchParams();
  params.append('task', task);

  Object.entries(inParams).forEach(([key, value]) => {
    params.append(key, value);
  });

  getInstance().post(target, params).then((response) => {
    if ('success' in response.data) {
      if (callback !== undefined) callback(response.data.success);
    } else if('fail' in response.data) {
      if (failCallback !== undefined) failCallback(response.data.fail);
    } else {
      errorCallback(response.data.error || response.data);
    }
  });

}

