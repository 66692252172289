import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.scss';

import HomePage from './pages/homepage/HomePage';
import RegisterPage from './pages/register/RegisterPage';
import RegisteredPage from './pages/registered/RegisteredPage';
import LoginPage from './pages/login/LoginPage';
import AdminPage from './pages/admin/AdminPage';
import ResetPage from './pages/reset/ResetPage';


function App() {
  return (
    <div className="app"> 
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<RegisterPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/success" element={<RegisteredPage />} />
          {/* <Route path="/login" element={<LoginPage />} />
          <Route path="/admin" element={<AdminPage />} />
          <Route path="/reset/:otp" element={<ResetPage />} />  */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
