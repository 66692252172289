import * as coreServices from './coreServices';


export const register = (inParams, callback, failCallback) => {
  coreServices.onServer(
    'club.php',
    'register', inParams,
    callback,
    failCallback
  );
}

export const login = (inParams, success, fail) => {
  coreServices.onServer(
    'club.php',
    'login', inParams,
    success,
    fail
  );
}

export const forgottenPwd = (inParams, success, fail) => {
  coreServices.onServer(
    'club.php',
    'forgotten', inParams,
    success, fail
  );
}

export const resetPassword = (inParams, success, fail) => {
  coreServices.onServer(
    'club.php',
    'reset', inParams,
    success, fail
  );
}

/* Get a list of all active clubs */
export const getAll = (callback) => {
  coreServices.onServer(
    'club.php',
    'getAll', [],
    callback
  ); 
}

export const getReferences = (success) => {
  coreServices.onServer(
    'club.php',
    'referencesList', [],
    success
  );
}


