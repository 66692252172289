import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";

import { useForm } from "react-hook-form";

import * as clubServices from 'services/clubServices';

import tnc from 'assets/docs/Tasfoods Betta Milk Sports Giveaway Competition Terms & Conditions.pdf';

import './RegisterForm.scss';


const Input = props => {
  const { name, children, errors, help } = props;
  return (
    <>
      {name && <label>{name}:</label>}
      <div>
        {children}
        {help && <span class="help">{help}</span>}
        {errors && <span class="error">{errors.message}</span>}
      </div>
    </>
  );
}

export default function RegisterForm(props) {


  const navigate = useNavigate();
  // const [success, setSuccess] = useState(false);
  const { register, handleSubmit, formState: { errors }, watch, setError } = useForm({});
  //const [data, setData] = useState({});
  let _data = {}; // useState being asyc is causing old reference to be passed through _handleSubmit to _handleFail and through service 

  const password = React.useRef({});
  password.current = watch("password", "");

  const generateMailto = () => {
    const nl = '%0D%0A';
    let rtn = 'mailto:sportsgiveaway@bettamilk.com.au?subject=Betta Sports Giveaway - I need assistance&body=Someone has registered my club without permission.' + nl + nl;
    Object.keys(_data).filter(key => (!key.startsWith('password'))).forEach((key) => rtn += key + " : " + _data[key] + nl);
    return rtn;
  }

  const _handleFail = (fail) => {
    if (fail.type === 'duplicate') {
      setError(
        fail.field, { type: "duplicate", message: <span className="stupid">Sorry, it appears that this club has already been registered. If you are the owner of the club, <a href={generateMailto()}>DISPUTE HERE</a>.</span> }, { shouldFocus: true }
      );
    }
  }

  const _handleSuccess = () => {
    navigate('/success');
  }

  const _handleSubmit = (data) => {
    _data = data;
    clubServices.register({ ...data }, _handleSuccess, _handleFail);
  }


  return (<form onSubmit={handleSubmit(_handleSubmit)}>

    <h4>your club details</h4>
    <fieldset className="club">
      <Input errors={errors.name} name="club name*">
        <input
          {...register("name", {
            required: "You must enter a club name",
            maxLength: 255
          })} />
      </Input>

      <Input
        name="club initials"
        errors={errors.initials}
        help={<>Pick 0-6 characters for your sports club as your club initials.</>}>
        <input
          {...register("initials", {
            maxLength: {
              value: 6,
              message: "Maximum of 6 characters"
            }
          })} />
      </Input>

      <Input
        name="club ABN"
        errors={errors.abn}
        help="Please provide ABN for your sports club for verification if you have one.">
        <input
          {...register("abn", {
            maxLength: {
              value: 11,
              message: "Must be 11 digits"
            },
            minLength: {
              value: 11,
              message: "Must be 11 digits"
            }
          })} />
      </Input>

    </fieldset>

    <h4>authorised admin contact details</h4>
    <p>The detail you enter must be associated to your club to be verified as part of the registration process.</p>
    <fieldset className="person">
      <Input name="first name*" errors={errors.first_name}>
        <input
          {...register("first_name", {
            required: "You must enter your first name",
            maxLength: 255
          })} />
      </Input>

      <Input name="last name*" errors={errors.surname}>
        <input
          {...register("surname", {
            required: "You must enter your surname",
            maxLength: 255
          })} />
      </Input>

      <Input name="phone number*" errors={errors.phone}>
        <input type="number"
          {...register("phone", {
            required: "You must enter your contact phone number",
            maxLength: {
              value: 16,
              message: "Can't be more than 16 digits"
            }
          })} />
      </Input>

      <Input name="email address*" errors={errors.email}>
        <input
          {...register("email", {
            required: "You must enter a valid email address",
            pattern: {
              value: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
              message: "Must be a valid email address"
            }
          })} />
      </Input>

      <Input name="password*" errors={errors.password}>
        <input type="password"
          {...register("password", {
            required: "You must choose a password",
            minLength: {
              value: 8,
              message: "Must be at least 8 characters"
            }
          })} />
      </Input>

      <Input name="confirm password*" errors={errors.password2}>
        <input type="password"
          {...register("password2", {
            validate: value =>
              value === password.current || "The passwords do not match"
          })} />
      </Input>

      <Input errors={errors.tnc}>
        <span class="checkbox">
          <input type="checkbox" id="tnc"
            {...register("tnc", {
              required: "You must accept the Terms & Conditions",
            })}
          /><label for="tnc">I confirm that I have read and accepted the <a href={tnc} target="_blank">Terms &amp; Conditions</a>.*</label>
        </span>
      </Input>
    </fieldset>

    <input type="submit" value="submit" />

  </form>
  );
}
