import React, { useState } from 'react';
import { Link } from "react-router-dom";

import './PageShell.scss';
import Footer from 'components/Footer';
import MobileNav from 'components/MobileNav';

import tnc from 'assets/docs/Tasfoods Betta Milk Sports Giveaway Competition Terms & Conditions.pdf';
import pp from 'assets/docs/Tasfoods Betta Milk Privacy Policy.pdf';

import logo from 'assets/images/betta_campaign_badge.png';
import lockup from 'assets/images/bettamilk_campaign_collect_to_win_2022.png';
import dazzle from 'assets/images/dazzle.svg';
import razzle from 'assets/images/razzle.svg';


export default function RegisterPage(props) {

  const Intro = () => {
    if (props.hideIntro) {
      return <></>;
    }
    return <>
      <h2>register your sports club now</h2>
      <p>Betta Milk is giving away <strong>$100,000 in cash</strong> to help grassroots sports teams across Tasmania! <strong>All</strong> registered sports clubs across Tassie have the chance to win a share of the cash prize. </p>
      <blockquote>
        <h2>what you need to do</h2>
        <ol>
          <li><strong>Register</strong> your club/team below.</li>
          <li><strong>Collect</strong> tokens on specially marked packs of Betta Milk.</li>
          <li><strong>Redeem </strong> tokens at bettamilk.com.au to climb the prize ladder.</li>
        </ol>
      </blockquote>

      <p><strong>Betta Sports Giveaway starts <span>1<sup>st</sup> September 2022</span>.</strong></p>
      <p>The more tokens collected, the more your club could win!*</p>
      <p className="sub">* <a href={tnc} target="_blank">Terms &amp; Conditions</a> apply.</p></>
  }

  return (<>
    <MobileNav />

    <div id={props.page_id} className='pageShell'>

      <header className="primary">
        <img className="logo" src={logo} />
        <Link to="/login" className="login">club admin access</Link>
      </header>

      <img id="dazzle" src={dazzle} />

      <div className="lockup">
        <img src={lockup} />
      </div>


      <section className="form">
      
        <Intro />

        {props.children}


        <footer className="sub">
          Need Help? <a href="mailto:support@bettamilk.com.au">Email Us</a> {/*| <a href="#"><span className="stupid">View our </span>FAQ<span className="stupid">s here</span></a> */}<br /><span class="pc">&nbsp;|&nbsp;</span>
          <a href={tnc} target="_blank">Terms &amp; Conditions</a> | <a href={pp} target="_blank">Privacy Policy</a>
        </footer>
      </section>

      <img id="razzle" src={razzle} />

      <Footer />
    </div>
  </>

  );
}
