import React, { useState } from 'react';

import PageShell from 'components/PageShell';


import logo from 'assets/images/betta_campaign_badge.png';
import './RegisteredPage.scss';


export default function RegisteredPage(props) {

  return (
    <PageShell page_id="success" hideIntro={true}>

      <h2>Thank you for registering</h2>
      <h2 className="bigger">Success!</h2>
      <p> The application for your sports club to register for the Betta Sports Giveaway has been submitted.</p>
      <p>You’ll receive an email to your inbox shortly to confirm that your club has been registered.</p>
      <p>Please be sure to check your inbox and spam folder, just in case.</p>

      <div className="lockup2">
        <img src={logo} />
        <span>
          <h4>
            RACE Starts on<br /> 1<sup>st</sup> September 2022.
          </h4>
          <h2>Get ready to collect,<br /> get ready to race.</h2>
        </span>
      </div>

    </PageShell>
  );
}
