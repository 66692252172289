import React, { useState } from 'react';
import { Link } from "react-router-dom";


import PageShell from 'components/PageShell';
import RegisterForm from 'components/RegisterForm';

import './RegisterPage.scss';




export default function RegisterPage(props) {
 
  return (
    <PageShell page_id="register">

      <nav class="secondary">
        <Link to="/register">Register<span class="extra">&nbsp;your club now</span></Link>
        <Link to="/login"><span class="extra">Already registered?&nbsp;</span>login<span class="extra">&nbsp;here</span></Link>
      </nav>

      <RegisterForm />
      
    </PageShell>
  );
}
