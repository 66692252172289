import React from 'react';

import './Footer.scss';

const Footer = (props) => {
  return <footer className="primary">
    <a className="betta" href="https://bettamilk.com.au">bettamilk.com.au</a>
    <span class="copy">&copy; COPYRIGHT 2022 Bettamilk. All rights reserved.</span>
    <span class="credit">Website created by <a href="https://purposemarketing.com.au">PURPOSE Marketing.</a></span>
  </footer>
}

export default Footer;