import React from "react";
import { Link } from "react-router-dom";

import './MobileNav.scss';

import logo from 'assets/images/betta_campaign_badge.png';
import razzle from 'assets/images/razzle_mob.svg';
import dazzle from 'assets/images/dazzle_mob.svg';

import tnc from 'assets/docs/Tasfoods Betta Milk Sports Giveaway Competition Terms & Conditions.pdf';

import Footer from "components/Footer";

const MobileNav = (props) => {

  const [open, setOpen] = React.useState(false);

  const [scrollPosition, setScrollPosition] = React.useState(0);
  const _handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  React.useEffect(() => {
    window.addEventListener("scroll", _handleScroll);
    return () => {
      window.removeEventListener("scroll", _handleScroll);
    };
  }, []);

  const _handleToggle = (e) => {
    setOpen(!open);
  }

  const className = (scrollPosition > 0 ? 'scrolled ' : '') + (open ? 'showMobNav' : '');

  return <div className={className}>
    <a href="#" id="navTrigger" onClick={_handleToggle}></a>
    <header className="mobile">
      <a href="#" id="toTop">return to top</a>
    </header>
    <div id="mobileNav">
      <a href="#" className="closer" onClick={_handleToggle}>x</a>
      <img id="dazzle_mob" src={dazzle} />
      <div>
        <img className="logo" src={logo} />
        <nav>
          {/* <a href="#">FAQ<span className="stupid">S</span></a> */}
          <a href={tnc} target="_blank">Terms &amp; Conditions</a>
          <Link to="/login" className="login">club admin access</Link>
        </nav>
        <Footer />
      </div>
      <img id="razzle_mob" src={razzle} />
    </div>
  </div>;

}

export default MobileNav;